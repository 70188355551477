import React, { useContext, useEffect, useState } from 'react'
import StatisticsContext from '../StatisticsContext'
import { createRally } from '../../../../services/StatsService'
import AuthContext from '../../../../context/AuthContext/AuthContext'
import PracticeStatisticsItemComponent from './PracticeStatisticsListItemComponent'
import { Button } from 'primereact/button'
import { PiArrowClockwise, PiArrowUUpLeft, PiCaretLeft, PiCheckBold, PiColumns, PiRows } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'
import { MultiSelect } from 'primereact/multiselect'
import RallyComponent from './RallyComponent'        
import { Divider } from 'primereact/divider'
import ShowPracticeStats from '../ShowPracticeStats'
import PracticeStatisticsListComponent from './PracticeStatisticsListComponent'
import PracticeStatisticsSideComponent from './PracticeStatisticsSideComponent'
import { InputSwitch } from 'primereact/inputswitch';
import ManageTeamComponent from './ManageTeamComponent'
        

function PracticeStatisticsComponent() {

    const {gridData, playersA, setPlayersA, rotationA, setMatchContext, setSetMatchContext,
        currentRallyContext, setCurrentRallyContext, handleRotationA} = useContext(StatisticsContext)

    const [currentPlayerAction, setCurrentPlayerAction] = useState();
    const {userAuth} = useContext(AuthContext)
    const [listLayout, setListLayout] = useState(true)
    const [isGameMode, setIsGameMode] = useState(false)

    const navigate = useNavigate()

    useEffect(()=>{
        gridData && setPlayersA(gridData.playersTeamA)
    },[gridData])

    useEffect(()=>{
        if (currentPlayerAction) {
            setCurrentRallyContext([...currentRallyContext, currentPlayerAction])
            setCurrentPlayerAction()
        }
    },[currentPlayerAction])

    const changePositionA = (position, index) => {
        var playersList = [...playersA]
        playersList[index].position = Number(position)
        playersList.sort((a,b)=>(a.position ? Number(a.position) : 100) - Number(b.position ? Number(b.position) : 100))
        setPlayersA(playersList)
    }

    const changePlayerPosition = (_pos) => {
        var newPosition = _pos - 1
        newPosition = newPosition === 0 ? 6 : newPosition
        return newPosition
    }

    useEffect(()=>{
        if (playersA) {
            var tempPlayers = [...playersA]
            setPlayersA(tempPlayers.map((e)=>{
                return (
                    {
                        ...e,
                        position: e.position ? changePlayerPosition(e.position) : '',
                    }
                )
            }))
        }
    },[rotationA])

    const finishPracticeRally = () => {
        createRally({match_id: gridData.matchId, created_by: userAuth.user.access_key, rally: currentRallyContext}).then((res)=>{
            res && setCurrentRallyContext([])
        }).catch((error)=>{
            
        })
    }

    const handleSetMatch = () => {
        setSetMatchContext(setMatchContext === 5 ? 1 : setMatchContext + 1)
    }
    
    return (
        <div className='m-2 flex flex-column gap-2' 
        style={{
            WebkitUserSelect: 'none', /* Safari */        
            MozUserSelect: 'none', /* Firefox */
            msUserSelect: 'none', /* IE10+/Edge */
            userSelect: 'none', /* Standard */}}>
            <div className='flex gap-2 align-items-center justify-content-between'>
                <div className='flex gap-2'>
                    <Button size='small' onClick={()=>navigate('/app/mystats')} className='flex justify-content-between w-max gap-2 p-2 h-full'>
                        <PiCaretLeft size={18} />
                    </Button>
                </div>
                <div className='flex gap-2 flex-wrap'>
                    <div className="card flex justify-content-center align-items-end gap-2">
                        <label>Game Mode</label>
                        <InputSwitch checked={isGameMode} onChange={(e) => setIsGameMode(!isGameMode)} />
                    </div> 
                    <Button size='small' className={"w-max h-full p-2"} onClick={handleSetMatch}>
                        {setMatchContext} set
                    </Button>
                    <Button size='small' className={"w-max h-full p-2"} onClick={()=>setListLayout(!listLayout)}>
                        {listLayout ? <PiColumns size={18} /> : <PiRows size={18} />}
                    </Button>
                    <ManageTeamComponent />
                    <Button size='small' onClick={()=>handleRotationA(rotationA)} className='flex justify-content-between w-max gap-2 p-2 h-full'>
                        {rotationA} <PiArrowClockwise className='-rotate-90' size={18} />
                    </Button>
                    <div className='border-x-1 border-300 m-1'></div>
                    <RallyComponent saveRally={finishPracticeRally} />
                </div>
            </div>
            {!listLayout ? <PracticeStatisticsListComponent /> : <PracticeStatisticsSideComponent isGameMode={isGameMode} />}
            <Divider />
            <ShowPracticeStats setMatch={setMatchContext} />
            <ShowPracticeStats />
        </div>
    )

}

export default PracticeStatisticsComponent