import React, { useEffect, useRef, useState } from 'react'
import { getGameStatsRallyPhaseByDates, getGameStatsRallyPhaseByMatch, getRotationsStatsByDate } from '../../../services/StatsService'
import ShowGameStatsItemComponent from './ShowGameStatsItemComponent'
import { Button } from 'primereact/button'
import { MultiSelect } from 'primereact/multiselect';
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { Calendar } from 'primereact/calendar';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
        

function ShowTeamRotationsDateStats({refresData = false, setRefreshReportData}) {
    const {team_access_key} = useParams()
    const [setAttackEfficiencyList, setSetAttackEfficiencyList] = useState()
    const [data, setData] = useState()
    const originalDataRef = useRef()
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const rally_phases = ['FBSO', 'SO', 'TRA']    
    const [rallyPhase, setRallyPhase] = useState(rally_phases);
    const rotations = ["1", "2", "3", "4", "5", "6"];
    const [selectedRotations, setSelectedRotations] = useState(rotations);
    const actions = ["Attack", "Set", "Reception", "Serve"];
    const [selectedActions, setSelectedActions] = useState(actions);
    const [teamAStatsAvg, setTeamAStatsAvg] = useState()
    const [rotationsStatsA, setRotationsStatsA] = useState()
    const [expandedRows, setExpandedRows] = useState([]);
    const [rotationsAAvg, setRotationsAAvg] = useState([{
        rotation: 1,
        fbso_point: 0,
        so_point: 0,
        tra_point: 0,
        fbso_error: 0,
        so_error: 0,
        tra_error: 0,
    },{
        rotation: 2,
        fbso_point: 0,
        so_point: 0,
        tra_point: 0,
        fbso_error: 0,
        so_error: 0,
        tra_error: 0,
    },{
        rotation: 3,
        fbso_point: 0,
        so_point: 0,
        tra_point: 0,
        fbso_error: 0,
        so_error: 0,
        tra_error: 0,
    },{
        rotation: 4,
        fbso_point: 0,
        so_point: 0,
        tra_point: 0,
        fbso_error: 0,
        so_error: 0,
        tra_error: 0,
    },{
        rotation: 5,
        fbso_point: 0,
        so_point: 0,
        tra_point: 0,
        fbso_error: 0,
        so_error: 0,
        tra_error: 0,
    },{
        rotation: 6,
        fbso_point: 0,
        so_point: 0,
        tra_point: 0,
        fbso_error: 0,
        so_error: 0,
        tra_error: 0,
    },]) 

    const types = [{value: 0, text: 'All'}, {value: 1, text: 'Game Season'}, {value: 2, text: 'Game Pre-Season'}, {value: 3, text: 'Practice Season'}, {value: 4, text: 'Practice Pre-Season'}, ]
    const [selectedType, setSelectedType] = useState(types[0].value)

    const handleGetGameStatsRallyPhaseByDates = () => {
        startDate && endDate && getGameStatsRallyPhaseByDates(team_access_key, startDate, endDate).then((res)=>{
            console.log(res)
            if(!res) return
            var _rotationsA = []

            var _teamASum = {
                fbso_point: 0,
                so_point: 0,
                tra_point: 0,
                fbso_error: 0,
                so_error: 0,
                tra_error: 0,
            }

            var _rotationsAAvg = [...rotationsAAvg]

            res?.forEach(e=>{
                var _teamA = {
                    fbso_percentage: 0,
                    so_percentage: 0,
                    tra_percentage: 0,
                }

                _teamA.name = e?.team_name_a

                _teamA.fbso_percentage = Number(e.fbso_point_a) ? Number(e.fbso_point_a)/(Number(e.fbso_point_a)+Number(e.fbso_error_a)) : 0
                _teamASum.fbso_point += Number(e.fbso_point_a)
                _teamASum.fbso_error += Number(e.fbso_error_a)
                _rotationsAAvg[e.rotation-1].fbso_point += Number(e.fbso_point_a)
                _rotationsAAvg[e.rotation-1].fbso_error += Number(e.fbso_error_a)

                _teamA.so_percentage = Number(e.so_point_a) ? Number(e.so_point_a)/(Number(e.so_point_a)+Number(e.so_error_a)) : 0
                _teamASum.so_point += Number(e.so_point_a)
                _teamASum.so_error += Number(e.so_error_a)
                _rotationsAAvg[e.rotation-1].so_point += Number(e.so_point_a)
                _rotationsAAvg[e.rotation-1].so_error += Number(e.so_error_a)

                _teamA.tra_percentage = Number(e.tra_point_a) ? Number(e.tra_point_a)/(Number(e.tra_point_a)+Number(e.tra_error_a)) : 0
                _teamASum.tra_point += Number(e.tra_point_a)
                _teamASum.tra_error += Number(e.tra_error_a)
                _rotationsAAvg[e.rotation-1].tra_point += Number(e.tra_point_a)
                _rotationsAAvg[e.rotation-1].tra_error += Number(e.tra_error_a)

                _rotationsA.push({
                    ..._teamA,
                    rotation: e.rotation,
                    set_match: e.set_match
                })
            })

            setRotationsAAvg(_rotationsAAvg)
            setRotationsStatsA(_rotationsA)

            const _teamAAvg = {
                name: res[0].team_name_a,
                fbso_percentage: (_teamASum.fbso_point > 0) ? _teamASum.fbso_point / (_teamASum.fbso_point + _teamASum.fbso_error) : 0,
                so_percentage: (_teamASum.so_point > 0) ? _teamASum.so_point / (_teamASum.so_point + _teamASum.so_error) : 0,
                tra_percentage: (_teamASum.tra_point > 0) ? _teamASum.tra_point / (_teamASum.tra_point + _teamASum.tra_error) : 0,
            }
            
            setTeamAStatsAvg(_teamAAvg)
        })
    }

    const onRotationsChange = (e) => {
        setSelectedRotations(e.value.sort());
    }

    const onActionsChange = (e) => {
        setSelectedActions(e.value.sort());
    }

    const onRallyPhasesChange = (e) => {
        setRallyPhase(e.value);
    }

    const handleGetStats = () => {
        if (startDate && endDate) {
            getRotationsStatsByDate(team_access_key, startDate, endDate, rallyPhase, selectedType).then((res)=>{
                if(res){
                    originalDataRef.current = res.rotations
                    var _teams = []
                    res.rotations?.forEach((item)=>{
                        !_teams.includes(item.team_name) && _teams.push(item.team_name)
                    })
                    refreshCourtsData()
                    setSetAttackEfficiencyList(res.efficiency)
                    handleGetGameStatsRallyPhaseByDates()
                }
            })
            setRefreshReportData && setRefreshReportData(false)
        } else {
            toast.error('Inform the start and end dates.')
        }
    }

    const refreshCourtsData = () => {
        if (originalDataRef.current) {
            const _data = originalDataRef.current.filter(e=> selectedActions.findIndex(i=>e.action_name === i) >= 0)
            setData(_data)
        }
    }

    const headerGroupTemplateA = (item) => {
        const _rotationsStatsA = rotationsAAvg[item.rotation-1]
        
        var fbso = (_rotationsStatsA.fbso_point > 0) ? (_rotationsStatsA.fbso_point/(_rotationsStatsA.fbso_error+_rotationsStatsA.fbso_point)) : 0
        var so = (_rotationsStatsA.so_point > 0) ? (_rotationsStatsA.so_point/(_rotationsStatsA.so_error+_rotationsStatsA.so_point)) : 0
        var tra = (_rotationsStatsA.tra_point > 0) ? (_rotationsStatsA.tra_point/(_rotationsStatsA.tra_error+_rotationsStatsA.tra_point)) : 0

        return (
            <React.Fragment>
                <span>Rotation {item.rotation}</span>
                <div className='bg-blue-100  flex justify-content-between'>
                    <div className="font-bold text-center p-1 w-2">Avg</div>
                    <div className="font-bold text-right p-1 flex-1">{(fbso*100).toFixed(2)}</div>
                    <div className="font-bold text-right p-1 flex-1">{(so*100).toFixed(2)}</div>
                    <div className="font-bold text-right p-1 flex-1">{(tra*100).toFixed(2)}</div>
                </div>
            </React.Fragment>
        );
    }

    const formatNumber = (number) => {
        return ((Number(number)*100).toFixed(2))
    }

    return (
        <div className='flex flex-column'>
            <Accordion multiple activeIndex={[0]} unstyled={true} className='flex flex-column gap-1'>
                <AccordionTab header={<label className='ml-2 text-lg'>Filters</label>} headerClassName='flex flex-1 p-3 gap-2 bg-gray-100 border-1 border-gray-300 border-round'>
                    <div className='flex flex-wrap gap-2 align-items-end justify-content-start p-2'>
                        <div className='flex align-items-center gap-2'>
                            <label htmlFor="startDate">Start Date</label>
                            <Calendar name="startDate" id="startDate" value={startDate} onChange={(e)=>setStartDate(e.target.value)} className='w-7rem' />
                            <label htmlFor="endDate">End Date</label>
                            <Calendar name="endDate" id="endDate" value={endDate} onChange={(e)=>setEndDate(e.target.value)} className='w-7rem' />
                        </div>
                        <MultiSelect value={selectedRotations} onChange={onRotationsChange} options={rotations} 
                            placeholder="Select Rotations" />
                        <MultiSelect value={selectedActions} onChange={onActionsChange} options={actions} 
                            placeholder="Select Actions" />
                        <MultiSelect value={rallyPhase} onChange={onRallyPhasesChange} options={rally_phases} 
                            placeholder="Select FBSO/SO/BP" />
                        <Dropdown value={selectedType} onChange={(e) => setSelectedType(e.value)} options={types} optionLabel="text" 
                            placeholder="Select a Type" />
                        <Button label='Refresh Data' onClick={handleGetStats} />
                    </div>
                </AccordionTab>
                <AccordionTab header={<label className='ml-2 text-lg'>Rotations</label>} headerClassName='flex p-3 gap-2 bg-gray-100 border-1 border-gray-300 border-round'>
                    <div className={'flex gap-2'}> 
                        <div className='flex flex-wrap w-full align-content-start justify-content-between gap-2 p-2'>
                            {data && data.map((item, key)=>(
                                <ShowGameStatsItemComponent item={item} key={key} teamAName={teamAStatsAvg?.name} setAttackEfficiencyList={setAttackEfficiencyList} />
                            ))}
                        </div>
                    </div>
                </AccordionTab>
                <AccordionTab header={<label className='ml-2 text-lg'>FBSO/SO/TRA</label>} headerClassName='flex p-3 gap-2 bg-gray-100 border-1 border-gray-300 border-round'>
                    <div className='flex flex-wrap justify-content-between gap-4 font-bold p-2'>
                        {teamAStatsAvg && rotationsStatsA &&
                        <div className='flex flex-1 gap-2 flex-column p-4 border-round' style={{backgroundColor: '#86bbd8'}}>
                            <div className='text-center text-xl'>{teamAStatsAvg?.name}</div>
                            <DataTable showGridlines stripedRows value={rotationsStatsA} rowGroupMode='subheader'
                            groupRowsBy="rotation" expandableRowGroups expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                            rowGroupHeaderTemplate={headerGroupTemplateA} >
                                <Column className='p-1 border-1 border-gray-900' align={'center'} field="set_match"
                                        footer="T" 
                                        alignHeader='center' headerClassName='p-1 border-1 border-gray-900' header="Set" />
                                
                                <Column className='p-1 border-1 border-gray-900' footer={(teamAStatsAvg.fbso_percentage*100).toFixed(2)} 
                                        alignHeader='center' headerClassName='p-1 border-1 border-gray-900' header="FBSO" 
                                        align={'right'} field={(item)=>formatNumber(item.fbso_percentage)} />
                                
                                <Column className='p-1 border-1 border-gray-900' footer={(teamAStatsAvg.so_percentage*100).toFixed(2)}
                                        headerClassName='p-1 border-1 border-gray-900' alignHeader='center' header="SO" 
                                        align={'right'} field={(item)=>formatNumber(item.so_percentage)}  />
                                
                                <Column header="BP" alignHeader={'center'} headerClassName='p-1 border-1 border-gray-900' 
                                        field={(item)=>formatNumber(item.tra_percentage)} className='p-1 border-1 border-gray-900' 
                                        footer={(teamAStatsAvg.tra_percentage*100).toFixed(2)} align={'right'} />
                            </DataTable>
                        </div>}
                    </div>
                </AccordionTab>
            </Accordion>
        </div>
    )
}

export default ShowTeamRotationsDateStats