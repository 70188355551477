import React, { useContext, useEffect, useRef, useState } from 'react'
import StatisticsContext from '../StatisticsContext'
import { createRally } from '../../../../services/StatsService'
import AuthContext from '../../../../context/AuthContext/AuthContext'
import MenuButtonsComponent from './MenuButtonsComponent'
import { actionsUtils } from '../Utils/ActionsUtils'
import { qualitiesAttack, qualitiesReception, qualitiesServe } from '../Utils/QualitiesUtils'
import { toast } from 'react-toastify'
import ShowGameStats from '../ShowGameStats'
import {Divider} from 'primereact/divider'


function GameGridStatisticsComponent() {

    const {gridData, playersA, setPlayersA, playersB, setPlayersB, rotationA, rotationB, activeTeam, setActiveTeam, 
        currentRallyContext, setCurrentRallyContext, handleRotationA, handleRotationB, currentActionRefContext, setMatchContext, 
        enabledAutoRotationContext} = useContext(StatisticsContext)
    const {userAuth} = useContext(AuthContext)
    const [gridLayout, setGridLayout] = useState(false)
    const [refreshReportData, setRefreshReportData] = useState(true)

    useEffect(()=>{
        gridData && handleInitialData()
    },[gridData])

    useEffect(()=>{
        currentActionRefContext.current = actions[5]
    },[])

    const handleInitialData = () => {
        setPlayersA(gridData?.playersTeamA?.map((e)=>{
            var color = '';
            if (e.position == 4) color = "#0dc61d"
            if (e.position == 1) color = "#3af25b"
            if (e.position == 3) color = "#8398c4"
            if (e.position == 6) color = "#323035"
            if (e.position == 2) color = "#87663b"
            if (e.position == 5) color = "#b0dee5"
            return (
                {
                    ...e,
                    color,
                    courtPosition: Number(e.position),
                    label: Number(e.number),
                }
            )
        }))
        setPlayersB(gridData?.playersTeamB?.map((e)=>{
            var color = '';
            if (e.position == 4) color = "#0dc61d"
            if (e.position == 1) color = "#3af25b"
            if (e.position == 3) color = "#8398c4"
            if (e.position == 6) color = "#323035"
            if (e.position == 2) color = "#87663b"
            if (e.position == 5) color = "#b0dee5"
            return (
                {
                    ...e,
                    color,
                    courtPosition: Number(e.position),
                    label: e.player.first_name.charAt(0)+e.player.first_name.charAt(1),
                }
            )
        }))
    }

    const switchActiveTeam = () => {
        activeTeamRef.current = activeTeamRef.current === 2 ? 1 : 2
        setActiveTeam(activeTeamRef.current)
    }

    const handleActiveTeam = (_activeTeam) => {
        activeTeamRef.current = _activeTeam
        setActiveTeam(_activeTeam)
    }

    useEffect(()=>{
        activeTeamRef.current = activeTeam
    },[activeTeam])

    useEffect(()=>{
        rotationARef.current = rotationA
        rotationBRef.current = rotationB
    },[rotationA, rotationB])

    const actions = [
        {
            ...actionsUtils.attack,
        },
        {
            ...actionsUtils.block,
        },
        {
            ...actionsUtils.set,
        },
        {
            ...actionsUtils.reception,
        },
        {
            ...actionsUtils.dig,
        },
        {
            ...actionsUtils.serve,
        },
    ]
    
    const currentCourtPositionRef = useRef(null)
    const currentQualityRef = useRef();
    const currentPlayerRef = useRef()
    let currentPlayerAction = null;
    const [currentRallyState, setCurrentRallyState] = useState([]);
    const [selectedPosition, setSelectedPosition] = useState()
    const [clickedButton, setClickedButton] = useState()
    const activeTeamRef = useRef(activeTeam)
    const rotationARef = useRef()
    const rotationBRef = useRef()
    const [teamServing, setTeamServing] = useState()
    const teamServingRef = useRef(teamServing)
    const playersARef = useRef(playersA)
    
    useEffect(()=>{
        playersARef.current = playersA
    },[playersA])

    useEffect(()=>{
        console.log(clickedButton)
    },[clickedButton])

    useEffect(()=>{
        setCurrentRallyState(currentRallyContext)
    },[currentRallyContext])

    const setCurrentRally = (_rally) => {
        setCurrentRallyState(_rally)
        setCurrentRallyContext(_rally)
    }

    const getRallyPhase = () => {
        const _activeTeam = activeTeamRef.current === 1 ? gridData.teamA : gridData.teamB
        if (teamServingRef?.current?.id !== _activeTeam.id){
            if (currentRallyState.length <= 3)
                return 'fbso'
            else
                return 'so'
        } else {
            return 'tra'
        }
    }

    const finishAction = () => {
        currentPlayerAction = {
            player: currentPlayerRef.current ? currentPlayerRef.current : null, 
            player_id: currentPlayerRef.current ? currentPlayerRef.current.player.id : null, 
            playerName: '', 
            action_number: currentActionRefContext.current?.number, 
            action: currentActionRefContext.current?.id, 
            position_action: currentCourtPositionRef.current ? currentCourtPositionRef.current : null, 
            quality_value: currentQualityRef.current ? currentQualityRef.current.number : null, 
            quality: currentQualityRef.current ? currentQualityRef.current.id : null, 
            rotation: activeTeamRef.current === 1 ? rotationARef.current : rotationBRef.current, 
            position: null,
            team: activeTeamRef.current === 1 ? gridData.teamA : gridData.teamB, 
            rally_phase: getRallyPhase(),
            set_match: setMatchContext,
        }
        currentCourtPositionRef.current = null
        setCurrentRally([...currentRallyState, currentPlayerAction])
        currentPlayerRef.current = null
    }

    const setCurrentPlayerAByPosition = (_position) => {
        if (playersARef.current && _position && typeof(_position) === 'number') {
            const _index = playersARef.current.findIndex(e=>Number(e.position) === Number(_position))
            currentPlayerRef.current = _index >= 0 ? {...playersARef.current[_index]} : null
        }
    }

    const handleTeamServing = (team) => {
        teamServingRef.current = team
        setTeamServing(team)
    }

    const finishGameRally = () => {
        var hasPoint = false
        var actionPoint = null
        for (let index = 0; index < currentRallyState.length; index++) {
            const item = currentRallyState[index];
            hasPoint = item.action_number === "ser" && (Number(item.quality_value) === 4 || Number(item.quality_value) === 0) ? true : false

            if ((Number(item.quality_value) === 2 && (
                item.action_number === "att" || 
                item.action_number === "blo"))) {
                hasPoint = true
                actionPoint = item
            } else if (Number(item.quality_value) === 0 && (
                item.action_number === "att" || 
                item.action_number === "set" || 
                item.action_number === "ser" || 
                item.action_number === "blo" || 
                item.action_number === "dig")) {
                hasPoint = true
                actionPoint = item
            }
            if (hasPoint) break;
        }

        hasPoint ? 
        createRally({match_id: gridData.matchId, created_by: userAuth.user.access_key, rally: currentRallyState}).then((res)=>{
            if (res) {
                if (Number(actionPoint?.quality_value) === 2 && enabledAutoRotationContext) {
                    if (actionPoint.team?.id !== teamServingRef.current?.id) {
                        if (actionPoint.team?.id === gridData.teamA?.id) {
                            handleRotationA(rotationARef.current)
                            handleActiveTeam(1)
                        } else {
                            handleRotationB(rotationBRef.current)
                            handleActiveTeam(2)
                        }
                    }
                } else if (Number(actionPoint?.quality_value) === 0 && enabledAutoRotationContext) {
                    if (actionPoint.team?.id === teamServingRef.current?.id) {
                        if (actionPoint.team?.id === gridData.teamA?.id) {
                            handleRotationB(rotationBRef.current)
                            handleActiveTeam(2)
                        } else {
                            handleRotationA(rotationARef.current)
                            handleActiveTeam(1)
                        }
                    } else {
                        if (actionPoint.team?.id === gridData.teamA?.id) {
                            handleActiveTeam(2)
                        } else {
                            handleActiveTeam(1)
                        }
                    }
                }
                setRefreshReportData(true)
                restartRally()
                toast.success("Rally saved!")
            }
        }).catch((error)=>{
        }) : toast.error("Select Score or Error")
    }

    const handleChangePosition = (e) => {
        setSelectedPosition(e.value)
        changePositionA(e.value)
    }

    const changePositionA = (position) => {
        var playersList = [...playersA]
        playersList?.forEach((item) => {
            item.position = item.number === item.number ? position : item.position
        });
        setPlayersA(playersList)
    }

    const changePlayerPosition = (_pos) => {
        if (_pos){
            var newPosition = _pos - 1
            newPosition = newPosition === 0 ? 6 : newPosition
            return newPosition
        }
    }

    useEffect(()=>{
        if (playersA) {
            var tempPlayers = [...playersA]
            setPlayersA(tempPlayers.map((e)=>{
                return (
                    {
                        ...e,
                        position: changePlayerPosition(e.position),
                    }
                )
            }))
        }
    },[rotationA])

    useEffect(()=>{
        if (playersB){
            var tempPlayers = [...playersB]
            setPlayersB(tempPlayers.map((e)=>{
                return (
                    {
                        ...e,
                        position: changePlayerPosition(e.position),
                    }
                )
            }))
        }
    },[rotationB])

    const restartRally = () => {     
        currentActionRefContext.current = actions[5]
        setCurrentRally([])
    }

    const gridCss = 'flex flex-1 mt-4 text-2xl'
    const columnCss = 'flex flex-column flex-1 gap-2'
    const textGroupCss = 'flex gap-2'
    const textCss = 'border-1 border-round w-full h-6rem flex align-items-center justify-content-center'
    const pointCss = 'border-green-400 bg-green-300'
    const errorCss = 'border-red-400 bg-red-300'
    const noTextCss = 'w-full h-4rem'
    const serveCss = 'border-pink-400 bg-pink-100'
    const setCss = 'border-yellow-400 bg-yellow-100'
    const attackCss = 'border-cyan-400 bg-cyan-100'
    const clickedCss = 'border-bluegray-400 bg-bluegray-200'
    const teamActiveCss = 'border-bluegray-400 bg-bluegray-300'

    const handleActionPosition = (action, position, _activeTeam) => {
        if (action.number === "ser") {
            setCurrentPlayerAByPosition(1)
            handleTeamServing(activeTeam === 1 ? gridData?.teamA : gridData?.teamB)
        }
        setActiveTeam(_activeTeam)
        activeTeamRef.current = _activeTeam
        currentActionRefContext.current = action
        currentCourtPositionRef.current = position
        currentQualityRef.current = qualitiesAttack.one
        finishAction()
        
        if (action.number === "ser") {
            currentActionRefContext.current = actions[3]
        }
    }

    const handleServeQuality = (action, quality, _activeTeam) => {

        var _rallyContext = [...currentRallyContext]
        _rallyContext[0].quality = quality.id
        _rallyContext[0].quality_value = quality.number
        setCurrentRally(_rallyContext)

        if (action.number === "ser"){
            var qualityReception = null
            if (quality.number === 4) { // ace/point
                qualityReception = qualitiesReception.zero
            } else if (quality.number === 3){ // free ball return
                qualityReception = qualitiesReception.one
            } else if (quality.number === 2){ // set pins
                qualityReception = qualitiesReception.two
            } else if (quality.number === 1){ // set middle
                qualityReception = qualitiesReception.three
            } else if (quality.number === 0){ // serve error
                qualityReception = {number: 0}
                switchActiveTeam()
            }
    
            if (qualityReception.number > 0){
                switchActiveTeam()
                currentCourtPositionRef.current = _rallyContext[0].position_action
                currentActionRefContext.current = actions[3] // reception
                currentQualityRef.current = qualityReception
                finishAction()
                qualityReception.number === 1 && switchActiveTeam() 
            }
            currentActionRefContext.current = actions[2] // set
        }
    }

    const handleLastAction = (quality) => {
        const _rallyContext = [...currentRallyContext]
        
        const idx = _rallyContext.length-1
        
        _rallyContext[idx].quality_value = quality.number
        _rallyContext[idx].quality = quality.id

        setCurrentRallyContext(_rallyContext)
    }

    const servePositionsLayout = (_activeTeam) => {
        return(
            <div className={columnCss}>
                <div className={textGroupCss}>
                    <div onClick={()=>handleActionPosition(actions[5], 4, _activeTeam)} className={`${textCss} ${serveCss}`}>Ser 4</div>
                    <div onClick={()=>handleActionPosition(actions[5], 3, _activeTeam)} className={`${textCss} ${serveCss}`}>Ser 3</div>
                    <div onClick={()=>handleActionPosition(actions[5], 2, _activeTeam)} className={`${textCss} ${serveCss}`}>Ser 2</div>
                </div>
                <div className={textGroupCss}>
                    <div onClick={()=>handleActionPosition(actions[5], 5, _activeTeam)} className={`${textCss} ${serveCss}`}>Ser 5</div>
                    <div onClick={()=>handleActionPosition(actions[5], 6, _activeTeam)} className={`${textCss} ${serveCss}`}>Ser 6</div>
                    <div onClick={()=>handleActionPosition(actions[5], 1, _activeTeam)} className={`${textCss} ${serveCss}`}>Ser 1</div>
                </div>
            </div>
        )
    }

    const setPositionsLayout = (_activeTeam) => {
        return(
            <div className={columnCss}>
                <div className={textGroupCss}>
                    <div onClick={()=>{handleActionPosition(actions[2], 4, _activeTeam); setClickedButton(12+''+_activeTeam)}} className={`${textCss} ${setCss} ${clickedButton === 12+''+_activeTeam ? clickedCss : ''}`}>Set 4</div>
                    <div onClick={()=>{handleActionPosition(actions[2], 3, _activeTeam); setClickedButton(13+''+_activeTeam)}} className={`${textCss} ${setCss} ${clickedButton === 13+''+_activeTeam ? clickedCss : ''}`}>Set 3</div>
                    <div onClick={()=>{handleActionPosition(actions[2], 2, _activeTeam); setClickedButton(14+''+_activeTeam)}} className={`${textCss} ${setCss} ${clickedButton === 14+''+_activeTeam ? clickedCss : ''}`}>Set 2</div>
                </div>
                <div className={textGroupCss}>
                    <div onClick={()=>{handleActionPosition(actions[2], 5, _activeTeam); setClickedButton(15+''+_activeTeam)}} className={`${textCss} ${setCss} ${clickedButton === 15+''+_activeTeam ? clickedCss : ''}`}>Set 5</div>
                    <div onClick={()=>{handleActionPosition(actions[2], 6, _activeTeam); setClickedButton(16+''+_activeTeam)}} className={`${textCss} ${setCss} ${clickedButton === 16+''+_activeTeam ? clickedCss : ''}`}>Set 6</div>
                    <div onClick={()=>{handleActionPosition(actions[2], 1, _activeTeam); setClickedButton(17+''+_activeTeam)}} className={`${textCss} ${setCss} ${clickedButton === 17+''+_activeTeam ? clickedCss : ''}`}>Set 1</div>
                </div>
            </div>
        )
    }

    const attackPositionsLayout = (_activeTeam) => {
        return(
            <div className={columnCss}>
                <div className={textGroupCss}>
                    <div role='button' onClick={()=>{handleActionPosition(actions[0], 4, _activeTeam); setClickedButton(1+''+_activeTeam)}} className={`${textCss} ${attackCss} ${clickedButton === 1+''+_activeTeam ? clickedCss : ''}`}>Att 4</div>
                    <div role='button' onClick={()=>{handleActionPosition(actions[0], 3, _activeTeam); setClickedButton(2+''+_activeTeam)}} className={`${textCss} ${attackCss} ${clickedButton === 2+''+_activeTeam ? clickedCss : ''}`}>Att 3</div>
                    <div role='button' onClick={()=>{handleActionPosition(actions[0], 2, _activeTeam); setClickedButton(3+''+_activeTeam)}} className={`${textCss} ${attackCss} ${clickedButton === 3+''+_activeTeam ? clickedCss : ''}`}>Att 2</div>
                </div>
                <div className={textGroupCss}>
                    <div role='button' onClick={()=>{handleActionPosition(actions[0], 5, _activeTeam); setClickedButton(4+''+_activeTeam)}} className={`${textCss} ${attackCss} ${clickedButton === 4+''+_activeTeam ? clickedCss : ''}`}>Att 5</div>
                    <div role='button' onClick={()=>{handleActionPosition(actions[0], 6, _activeTeam); setClickedButton(5+''+_activeTeam)}} className={`${textCss} ${attackCss} ${clickedButton === 5+''+_activeTeam ? clickedCss : ''}`}>Att 6</div>
                    <div role='button' onClick={()=>{handleActionPosition(actions[0], 1, _activeTeam); setClickedButton(6+''+_activeTeam)}} className={`${textCss} ${attackCss} ${clickedButton === 6+''+_activeTeam ? clickedCss : ''}`}>Att 1</div>
                </div>
            </div>
        )
    }

    const serveQualitiesLayout = (_activeTeam) => {
        return (
            <div className={columnCss}>
                <div className={textGroupCss}>
                    <div role='button' onClick={()=>{handleServeQuality(actions[5], qualitiesServe.point, _activeTeam); setClickedButton(7+''+_activeTeam)}} className={`${textCss} border-green-400 bg-green-200 ${clickedButton === 7+''+_activeTeam ? clickedCss : ''}`}>Ace</div>
                    <div role='button' onClick={()=>{handleServeQuality(actions[5], qualitiesServe.freeBall, _activeTeam); setClickedButton(8+''+_activeTeam)}} className={`${textCss} border-green-400 bg-green-100 ${clickedButton === 8+''+_activeTeam ? clickedCss : ''}`}>Free</div>
                    <div role='button' onClick={()=>{handleServeQuality(actions[5], qualitiesServe.setPin, _activeTeam); setClickedButton(9+''+_activeTeam)}} className={`${textCss} border-yellow-400 bg-yellow-100 ${clickedButton === 9+''+_activeTeam ? clickedCss : ''}`}>Pins</div>
                </div>
                <div className={textGroupCss}>
                    <div role='button' onClick={()=>{handleServeQuality(actions[5], qualitiesServe.setMiddle, _activeTeam); setClickedButton(10+''+_activeTeam)}} className={`${textCss} border-red-400 bg-red-100 ${clickedButton === 10+''+_activeTeam ? clickedCss : ''}`}>Middle</div>
                    <div role='button' onClick={()=>{handleServeQuality(actions[5], qualitiesServe.error, _activeTeam); setClickedButton(11+''+_activeTeam)}} className={`${textCss} border-red-400 bg-red-200 ${clickedButton === 11+''+_activeTeam ? clickedCss : ''}`}>Error</div>
                    <div className={`${textCss}`}></div>
                </div>
            </div>
        )
    }

    const miscLayout = (_activeTeam) => {
        return (
            <div className={textGroupCss}>
                <div onClick={()=>{handleActionPosition(actions[1], null, _activeTeam); setClickedButton(18+''+_activeTeam)}} className={`${textCss} border-purple-400 bg-purple-100 ${clickedButton === 18+''+_activeTeam ? clickedCss : ''}`}>Blo</div>
                <div onClick={()=>{handleActionPosition(actions[4], null, _activeTeam); setClickedButton(19+''+_activeTeam)}} className={`${textCss} border-indigo-400 bg-indigo-100 ${clickedButton === 19+''+_activeTeam ? clickedCss : ''}`}>Dig</div>
                <div onClick={()=>{handleLastAction(qualitiesAttack.two); setClickedButton(20+''+_activeTeam)}} className={`${textCss} ${pointCss} ${clickedButton === 20+''+_activeTeam ? clickedCss : ''}`}>Score</div>                        
                <div onClick={()=>{handleLastAction(qualitiesAttack.zero); setClickedButton(21+''+_activeTeam)}} className={`${textCss} ${errorCss} ${clickedButton === 21+''+_activeTeam ? clickedCss : ''}`}>Error</div>                        
            </div>
        )
    }

    return (
        <div>
            <div>
                <MenuButtonsComponent 
                    gridLayout={gridLayout} 
                    setGridLayout={setGridLayout} 
                    selectedPosition={selectedPosition} 
                    handleChangePosition={handleChangePosition} 
                    setTeamServing={setTeamServing}
                    courtType={''}
                    saveRally={finishGameRally}
                    restartRally={restartRally}
                />
                <div className={gridCss}>
                    <div className={columnCss}>
                        {
                            (activeTeam === 1 && currentActionRefContext?.current?.number === "ser") ? 
                            servePositionsLayout(1) : 
                            (activeTeam === 1 && currentActionRefContext?.current?.number === "rec") ? 
                            serveQualitiesLayout(1) : 
                            currentActionRefContext?.current?.number != "rec" && currentActionRefContext?.current?.number != "ser" ?
                            <div className='flex flex-column gap-2'>
                                {setPositionsLayout(1)}
                                {attackPositionsLayout(1)}
                                {miscLayout(1)}
                            </div> :
                            ''
                        }
                    </div>
                    <Divider layout="vertical" />
                    <div className={columnCss}>
                        {
                            (activeTeam === 2 && currentActionRefContext?.current?.number === "rec") ? 
                            serveQualitiesLayout(2) :
                            (activeTeam === 2 && currentActionRefContext?.current?.number === "ser") ? 
                            servePositionsLayout(2) : 
                            currentActionRefContext?.current?.number != "rec" && currentActionRefContext?.current?.number != "ser" ?
                            <div className='flex flex-column gap-2'>
                                {setPositionsLayout(2)}
                                {attackPositionsLayout(2)}
                                {miscLayout(2)}
                            </div> :
                            ''
                        }
                    </div>                  
                </div>
            </div>
            <Divider />
            <ShowGameStats refresData={refreshReportData} setRefreshReportData={setRefreshReportData} />
        </div>
    )

}

export default GameGridStatisticsComponent