import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import TableStatsComponent from './Components/TableStatsComponent';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { getPlayerStatsHistory, getPositionStatsHistory, getTeamStatsHistory } from '../../../services/StatsService';
import UserAutocompleteComponent from '../../../components/UserAutocompleteComponent/UserAutocompleteComponent';
import { Chart } from 'primereact/chart';

function ShowHistoryStats() {
    
    const {access_key} = useParams()
    const [user, setUser] = useState()
    const [positionId, setPositionId] = useState()
    const [dataPlayer, setDataPlayer] = useState()
    const [dataTeam, setDataTeam] = useState()
    const [dataPosition, setDataPosition] = useState()
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const skills = [
        {value: 9, label: 'Middle Blocker'},
        {value: 10, label: 'Outside Hitter'},
        {value: 11, label: 'Opposite Hitter'},
        {value: 12, label: 'Setter'},
        {value: 14, label: 'Libero'},
    ]

    const actions = [
        {value: 1, label: 'Serve'},
        {value: 2, label: 'Reception'},
        {value: 3, label: 'Attack'},
        {value: 4, label: 'Block'},
        {value: 5, label: 'Dig'},
        {value: 6, label: 'Set'},
    ]
    const [actionSelected, setActionSelected] = useState(actions[0].value)



    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        dataPlayer && dataTeam && dataPosition && actionSelected && handleChartData()
    }, [dataPlayer, dataTeam, dataPosition, actionSelected]);

    const handleChartData = () => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const language = navigator.language

        const _dataPlayer = dataPlayer.map(e=>{
            return actionSelected === 1 ? e.ser_eff : actionSelected === 2 ? e.rec_eff : actionSelected === 3 ? e.att_eff : actionSelected === 4 ? e.blo_eff : actionSelected === 5 ? e.dig_eff : actionSelected === 6 ? e.set_eff : 0
        })

        const _dataTeam = dataTeam.map(e=>{
            return actionSelected === 1 ? e.ser_eff : actionSelected === 2 ? e.rec_eff : actionSelected === 3 ? e.att_eff : actionSelected === 4 ? e.blo_eff : actionSelected === 5 ? e.dig_eff : actionSelected === 6 ? e.set_eff : 0
        })

        const _dataPosition = dataPosition.map(e=>{
            return actionSelected === 1 ? e.ser_eff : actionSelected === 2 ? e.rec_eff : actionSelected === 3 ? e.att_eff : actionSelected === 4 ? e.blo_eff : actionSelected === 5 ? e.dig_eff : actionSelected === 6 ? e.set_eff : 0
        })

        const data = {
            labels: dataPlayer.map(e=>new Date(e.week_start_date).toLocaleDateString(language)),
            datasets: [
                {
                    label: 'Player History',
                    data: _dataPlayer,
                    fill: false,
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    tension: 0.4
                },
                {
                    label: 'Team History',
                    data: _dataTeam,
                    fill: false,
                    borderColor: documentStyle.getPropertyValue('--pink-500'),
                    tension: 0.4
                },
                {
                    label: 'Position History',
                    data: _dataPosition,
                    fill: false,
                    borderColor: documentStyle.getPropertyValue('--green-500'),
                    tension: 0.4
                }
            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);

    }

    useEffect(()=>{
        access_key && handleGetTeamHistoryStats()
    },[access_key])

    useEffect(()=>{
        access_key && positionId && handleGetPositionHistoryStats()
    },[access_key, positionId])

    useEffect(()=>{
        user && handleGetPlayerHistoryStats()
    },[user])

    const handleGetPlayerHistoryStats = () => {
        user && startDate && endDate && getPlayerStatsHistory(user, startDate, endDate).then(res=>{
            setDataPlayer(res)
        })
    }

    const handleGetTeamHistoryStats = () => {
        access_key && startDate && endDate && getTeamStatsHistory(access_key, startDate, endDate).then(res=>{
            setDataTeam(res)
        })
    }

    const handleGetPositionHistoryStats = () => {
        access_key && positionId && startDate && endDate && getPositionStatsHistory(access_key, positionId, startDate, endDate).then(res=>{
            setDataPosition(res)
        })
    }

    const handleGetStatsHistory = () => {
        handleGetPlayerHistoryStats()
        handleGetPositionHistoryStats()
        handleGetTeamHistoryStats()
    }

    return (
        <div className='flex flex-column gap-4 m-4'>
            <div className='flex flex-wrap align-items-center gap-2'>
                <UserAutocompleteComponent setUser={setUser} user={user} />
                <Dropdown className='w-12rem' name='position' value={positionId} options={skills} 
                onChange={(e)=>setPositionId(e.value)} placeholder='Position' optionLabel='label' />    
                <label htmlFor="startDate">Start Date</label>
                <Calendar className='w-8rem' name="startDate" id="startDate" value={startDate} onChange={(e)=>setStartDate(e.target.value)} />
                <label htmlFor="endDate">End Date</label>
                <Calendar className='w-8rem' name="endDate" id="endDate" value={endDate} onChange={(e)=>setEndDate(e.target.value)} />
                <Button label='Refresh Data' className='w-max' onClick={handleGetStatsHistory} />
                <Dropdown className='w-8rem' name='action' value={actionSelected} options={actions} 
                onChange={(e)=>setActionSelected(e.value)} placeholder='Action' optionLabel='label' />
            </div>
            <div className="card">
                <Chart type="line" data={chartData} options={chartOptions} />
            </div>            
        </div>
    )
}

export default ShowHistoryStats