import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import React, { useContext, useEffect, useState } from 'react'
import { startStats } from '../../../services/StatsService'
import AuthContext from '../../../context/AuthContext/AuthContext'
import { useNavigate } from 'react-router-dom'
import StatisticsContext from './StatisticsContext'
import { ConfirmDialog } from 'primereact/confirmdialog'
import UserAutocompleteComponent from '../../../components/UserAutocompleteComponent/UserAutocompleteComponent'
import { Calendar } from 'primereact/calendar'

function StartStatsPlayerComoponent() {
    const [playersTeamA, setPlayersTeamA] = useState([])
    const [playersTeamB, setPlayersTeamB] = useState([])
    const [teamA, setTeamA] = useState('')
    const [teamB, setTeamB] = useState('')
    const [matchName, setMatchName] = useState('')
    const {userAuth} = useContext(AuthContext)
    const [date, setDate] = useState(new Date())
    const navigate = useNavigate()
    const [position, setPosition] = useState('')
    const [number, setNumber] = useState('')
    const [player, setPlayer] = useState('')

    const handleSetPlayersTeam = (player, number, position) => {
        if (player || number){
            const newPlayer = [{player, number, position}]
            setPlayersTeamA(newPlayer)
        }
    }

    useEffect(()=>{
        handleSetPlayersTeam(player, number, position)
    },[player, number])
    
    const handleStartStats = (statisticsType) => {
        startStats(matchName, statisticsType, teamA, teamB, playersTeamA, playersTeamB, userAuth.user.access_key, date).then(({matchId, matchAccessKey, teamAId, teamBId})=>{
            matchId && navigate('/app/statistics/'+matchAccessKey)
        })
    }
    return (
        <div className='flex flex-column gap-2'>
            <ConfirmDialog />
            <div className='flex gap-2'>
                <div className="flex flex-column gap-2 w-full">
                    <label htmlFor="match" className='text-l font-bold'>Title</label>
                    <InputText name='match' className='w-full p-inputtext-sm' onChange={(e)=>setMatchName(e.target.value)} />
                </div>
                <div className="flex flex-column gap-2 w-full">
                    <label htmlFor="date" className='font-bold'>Date</label>
                    <Calendar name='date' value={date} className='w-full p-inputtext-sm' onChange={(e)=>setDate(e.target.value)} />
                </div>
            </div>
            
            <div className='flex flex-column gap-2 w-full'>
                <div className='flex gap-1'>
                    <div className="flex flex-column gap-1 w-8">
                        <label htmlFor="player" className='font-bold'>Select Player</label>
                        <UserAutocompleteComponent setUser={(e)=>setPlayer(e)} size='p-inputtext-sm' /> 
                    </div>
                    <div className="flex flex-column gap-1 w-2">
                        <label htmlFor="number" className='font-bold'>Jersey #</label>
                        <InputText name='number' className='p-inputtext-sm' value={number} onChange={(e)=>setNumber(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className='flex gap-2'>
                <Button label='Start Player Stats' className='w-max' onClick={()=>handleStartStats('player')} />
            </div>
        </div>
    )
}

export default StartStatsPlayerComoponent